<template>
  <div>
    <v-container>
      <!-- Settings Gear, no Gear -->
      <SettingsGear :showIcon="false" />
      <!-- One Logo / Slogan -->
      <div class="one-logo">
        <div class="one-logo-content">
          <img src="@/assets/logos/dime_logo_gold.png" />
        </div>
      </div>
      <!-- Content -->
      <div class="content">
        <div class="content-logo">
          <!-- <img src="@/assets/logos/dime_thinkhigher.png" /> -->
        </div>
        <!-- Login -->
        <div class="content-form">
          <form style="width: 100%" @submit.prevent>
            <div class="mb-3">
              <input
                ref="NEWPASSWORD"
                type="password"
                :style="`border: 2px solid ${getAccentColor};`"
                placeholder="NEW PASSWORD"
                v-model.trim="payload.user.password"
                class="form-control py-2 form-input"
                autocomplete="on"
                maxlength="20"
                @blur="onBlurPassword"
              />
            </div>
            <div class="mb-3">
              <input
                ref="CONFIRMPASSWORD"
                type="password"
                :style="`border: 2px solid ${getAccentColor};`"
                placeholder="CONFIRM PASSWORD"
                v-model.trim="payload.user.confirmPassword"
                class="form-control py-2 form-input"
                autocomplete="on"
                maxlength="20"
                @blur="onBlurConfirmPassword"
              />
            </div>
            <template>
              <div class="mb-5">
                <button
                  class="text-white py-2 form-button"
                  :style="`background-color: ${getAccentColor};`"
                  @click="onClickSubmit"
                  :disabled="buttonDisabled"
                >
                  <span class="form-span">submit</span>
                </button>
              </div>
            </template>
          </form>
        </div>
      </div>
      <!-- Footer -->
      <div
        style="
          display: flex;
          height: 100%;
          align-items: center;
          justify-content: center;
          padding-bottom: 4vh;
        "
      >
        <img style="height: 3.5em" src="@/assets/logos/one_logo_black.png" />
      </div>
    </v-container>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { Toast } from "vant";

export default {
  name: "editPassword",
  data() {
    return {
      loading: {
        overall: true,
      },
      payload: {
        user: {
          password: "",
          confirmPassword: "",
        },
      },
      startChecking: false, //是否开始验证
    };
  },
  created() {
    this.setup();
    console.log(this.$route.params);
  },

  methods: {
    ...mapActions([
      "_setLoginState",
      "_setLoginInfo",
      "_setRegisterEmail",
      "_setPresetInfo",
    ]),
    /* API Calls */
    // GET
    // POST
    /* Main */
    async setup() {},
    onClickSubmit() {
      this.startChecking = true;
      if (
        this.payload.user.password.length > 0 &&
        this.payload.user.password.length < 6
      ) {
        this.$showTips({ msg: "Input a 6 digits password." });
        // Toast.fail("Input a 6 digits password.");
        this.$refs.NEWPASSWORD.style.borderColor = "#FF4141";
        this.$refs.NEWPASSWORD.focus();
        return;
      }
      if (
        this.payload.user.confirmPassword.length < 0 &&
        this.payload.user.confirmPassword.length < 6
      ) {
        this.$showTips({ msg: "Input a 6 digits confirmation password." });
        // Toast.fail("Input a 6 digits confirmation password.");
        this.$refs.CONFIRMPASSWORD.style.borderColor = "#FF4141";
        this.$refs.CONFIRMPASSWORD.focus();
        return;
      }
      if (this.payload.user.password != this.payload.user.confirmPassword) {
        this.$showTips({ msg: "The passwords do not match" });
        // Toast.fail("The passwords do not match");
        this.$refs.CONFIRMPASSWORD.style.borderColor = "#FF4141";
        this.$refs.CONFIRMPASSWORD.focus();
        return;
      }
      this.showLoading("Modifying...");
      this.$api.user
        .retrievePassword({
          code: this.$route.params.code,
          email: this.$route.params.email,
          password: this.payload.user.password,
        })
        .then((res) => {
          if (res.data.code === 200) {
            // Toast.success(res.data.message);
            this.$showTips({
              msg: res.data.message,
              duration: 3000,
              showType: "info",
            });
            setTimeout(() => {
              this.$router.push({ name: "login" });
            }, 3000);
          } else {
            this.$showTips({ msg: res.data.message });
            // Toast.fail(res.data.message);
          }
        })
        .catch((error) => {
          this.$showTips({ msg: error.data.message });
          // Toast.fail(error.data.message);
        });
    },
    /* Misc */
    async setLoadingState(loadingState) {
      this.loading.overall = loadingState;
    },
    //#region onBlurPassword
    onBlurPassword() {
      let value = this.payload.user.password;
      if (this.startChecking) {
        if (value.length == 0 || value.length < 6) {
          this.$refs.NEWPASSWORD.style.borderColor = "#FF4141";
        } else {
          this.$refs.NEWPASSWORD.style.borderColor = this.getAccentColor;
        }
      }
    },
    //#endregion
    //#region onBlurConfirmPassword
    onBlurConfirmPassword() {
      let value = this.payload.user.confirmPassword;
      if (this.startChecking) {
        if (value.length == 0 || value.length < 6) {
          this.$refs.CONFIRMPASSWORD.style.borderColor = "#FF4141";
        } else if (value != this.payload.user.password) {
          this.$refs.CONFIRMPASSWORD.style.borderColor = "#FF4141";
          // this.$refs.CONFIRMPASSWORD.focus();
        } else {
          this.$refs.CONFIRMPASSWORD.style.borderColor = this.getAccentColor;
        }
      }
    },
    //#endregion
  },
  computed: {
    ...mapGetters([
      "getMainColor",
      "getAccentColor",
      "getLoginState",
      "getActionColor",
    ]),
    buttonDisabled() {
      if (
        this.payload.user.password.length == 0 ||
        this.payload.user.confirmPassword.length == 0
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>

<style lang="less" scoped>
::placeholder {
  color: white;
  text-align: center;
}

.one-logo {
  height: 15vh;

  .one-logo-content {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;

    img {
      height: 7em;
    }
  }
}

.content {
  height: 58vh;

  .content-logo {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: flex-end;
    height: 6.5em;

    img {
      height: 2.5em;
    }

    .content-form {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      height: 100%;
    }
  }
}

.form-input {
  background-color: transparent;
  color: white;
  border-radius: 30px;
}

.form-button {
  border: none;
  width: 100%;
  border-radius: 30px;
  font-weight: 500;
}

.form-button:disabled {
  background: #959595 !important;
}

.form-span {
  text-transform: uppercase;
  font-size: 1.2em;
}

.form-col {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: transparent;
  border: 2px solid #ffab00;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 24px;
  width: 24px;
  left: 3px;
  bottom: 3px;
  background-color: #ffab00;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:focus + .slider {
  box-shadow: 0 0 1px #ffab00;
}

input:checked + .slider:before {
  -webkit-transform: translateX(27px);
  -ms-transform: translateX(27px);
  transform: translateX(27px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
</style>
